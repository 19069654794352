<template>
  <v-container fluid class="mb-5">
    <v-row>
      <v-col>
        <v-card :class="isMobile ? 'pa-0 ma-0': 'pl-2 pr-2' " elevation="0" tile>
          <v-tabs
            v-model="activeTab"
            background-color="transparent"
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.type"
              @click="changePath(tab.type)"
              class="font-weight-bold text-capitalize"
            >
              {{tab.label}}
            </v-tab>
          </v-tabs>
          <v-divider style="margin-top:-2px" />
        </v-card>
      </v-col>
    </v-row>
    <v-tabs-items v-model="activeTab">
      <v-tab-item v-for="(tab, index) in tabs" :key="index">
        <MainList
          :key="key[tab.type]"
          :title="tab.title"
          @refetchData="refetchData"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import MainList from './MainList';

export default {
  name: 'index-oracle-invoice',
  components: {
    MainList,
  },
  created() {
    const { type } = this.$route.params;
    const validTypes = ['REGULAR', 'FIXED_PRICE'];
    if (!validTypes.includes(type)) {
      this.$router.replace({
        params: {
          type: 'REGULAR',
        },
      });
    }
  },
  data() {
    return {
      type: '',
      tabs: [
        {
          label: 'On Call',
          title: 'Invoice On Call',
          type: 'REGULAR',
        },
        {
          label: 'Fixed Price',
          title: 'Invoice Fixed Price',
          type: 'FIXED_PRICE',
        },
      ],
      key: {
        REGULAR: 0,
        FIXED_PRICE: 0,
      },
      historyPath: {},
    };
  },
  watch: {
    $route: function setHistoryPath(newVal, oldVal) {
      this.historyPath[oldVal.params.type] = oldVal.fullPath;
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    activeTab: {
      set() {},
      get() {
        const { type } = this.$route.params;
        return this.tabs.findIndex((e) => e.type === type);
      },
    },
  },
  methods: {
    refetchData() {
      const { type } = this.$route.params;
      this.key[type] += 1;
    },
    changePath(type) {
      const nextTab = this.tabs.findIndex((e) => e.type === type);
      if (nextTab !== this.activeTab) {
        this.$router.replace(this.historyPath[type] || {
          params: {
            type,
          },
        });
      }
    },
  },
};
</script>
